var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "md",
        title: _vm.labelModalTitle,
        "footer-class": "footerClass",
        "no-close-on-backdrop": "",
        "content-class": "shadow about-modal-content",
        "hide-footer": "",
      },
      on: { hidden: _vm.hidden },
      model: {
        value: _vm.modalShow,
        callback: function ($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow",
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "about-logo-container",
              attrs: { cols: "12", md: "4" },
            },
            [
              _c("img", {
                staticClass: "about-logo",
                attrs: { src: "/img/brand/logo-icon.svg" },
              }),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "mb-3 mb-md-0", attrs: { cols: "12", md: "8" } },
            [
              _c(
                "b-row",
                { staticClass: "pt-0 pt-md-4" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-1 text-center text-md-left",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.labelLicense) },
                      }),
                      _vm._v(" " + _vm._s(_vm.company)),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-1 text-center text-md-left",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.labelUserLimit) },
                      }),
                      _vm._v(" " + _vm._s(_vm.userLimit)),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-1 text-center text-md-left",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.labelVersion) },
                      }),
                      _vm._v(" " + _vm._s(_vm.versionNumber)),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-2 text-center text-md-left",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "https://projectal.com",
                          },
                        },
                        [_vm._v("https://projectal.com")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center text-md-left", attrs: { cols: "12" } },
            [_vm._v(_vm._s(_vm.copyrightDisclaimer))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }