<template>
  <b-modal v-model="modalShow" size="md" :title="labelModalTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow about-modal-content" hide-footer
      @hidden="hidden">
    
    <b-row>
      <b-col cols="12" md="4" class="about-logo-container">
        <img class="about-logo" src="/img/brand/logo-icon.svg"/>
      </b-col>
      <b-col cols="12" md="8" class="mb-3 mb-md-0">
        <b-row class="pt-0 pt-md-4">
          <b-col cols="12" class="mb-1 text-center text-md-left"><span v-html="labelLicense"></span>  {{ company }}</b-col>
          <b-col cols="12" class="mb-1 text-center text-md-left"><span v-html="labelUserLimit"></span>  {{ userLimit }}</b-col>
          <b-col cols="12" class="mb-1 text-center text-md-left"><span v-html="labelVersion"></span>  {{ versionNumber }}</b-col>
          <b-col cols="12" class="mb-2 text-center text-md-left"><a target="_blank" href="https://projectal.com">https://projectal.com</a></b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-center text-md-left">{{ copyrightDisclaimer }}</b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { managementService } from '@/services/management.service.js';
export default {
  name: 'AboutModal'
  , components: {
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    , labelTitle: {
      type: String
      , default: null
    }
  }
  , data: function() {
    return {
      modalShow: false
      , versionNumber: 'N/A'
      , userLimit: 'N/A' 
    }
  }
  , created() {
    this.$store.dispatch('data/status').then(value => {
      this.versionNumber = value.version;
    })
    .catch(e => {
      this.versionNumber = "N/A";
    });
  }
  , mounted() {
    this.modalShow = this.show;
    if (this.show) {
      this.loadNumberOfUsersInfo();
    }
  }
  , watch: {
    show(newValue) {
      if (newValue) {
        this.modalShow = true;
        this.loadNumberOfUsersInfo();
      }
    }
  }
  , computed: {
    labelModalTitle() {
      return this.labelTitle != null? this.labelTitle : this.$t('about.title_projectal', ['Projectal']);
    },
    company() {
      return this.$store.state.company.name != null? this.$store.state.company.name : null;
    },
    labelLicense() {
      return `<strong>${this.$t('about.license')}</strong>`;
    },
    copyrightDisclaimer() {
      return `© ${(new Date()).getFullYear()} JanusKS Pty. Ltd.  All rights reserved.`;
    },
    labelVersion() {
      return `<strong>${this.$t('about.version')}</strong>`;
    },
    labelUserLimit() {
      return `<strong>${this.$t('about.userlimit')}</strong>`;
    }
  }
  , methods: {
    hidden() {
      this.$emit('update:show', false);
    },
    loadNumberOfUsersInfo() {
      managementService.getNumberOfUsersInfo()
      .then(data => {
        this.userLimit = typeof data.total === 'string' && data.total == 'unlimited' 
          ? this.$t('string.unlimited') 
          : data.total;
      })
      .catch(() => {
        this.userLimit = 'N/A';
      });
    }
  }
}
</script>

<style lang="scss">
  .about-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 153px;
  }
  .about-logo {
    width: 100px;
    height: 100px;
  }
  .about-modal-content > .modal-body {
    padding-top: 0px;
  }
  .about-details {
    padding-top: 10px;
  }
</style>